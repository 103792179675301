import {request} from './request'
import Cookies from 'js-cookie'
import qs from 'qs'
// const maker_id=Cookies.get("maker_id")
const maker_id=Cookies.get("maker_id")

export function getPresses(page,rows_per_page=10) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/press/getPresses.php', params:{page,maker_id,rows_per_page}})
}
export function getPressDetail(press_id) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/press/getPress.php', params:{press_id,maker_id}})
}
export function deletePress(press_id) {
    var maker_id = Cookies.get("maker_id");
    let data={press_id,maker_id}
    return request({url:'/press/deletePress.php',data:qs.stringify(data),method:'post'})
}
export function addPress(data) {
    data.maker_id = Cookies.get("maker_id");
    return request({url:'/press/addPress.php',data:qs.stringify(data),method:'post'})
}
export function editPress(data) {
    data.maker_id = Cookies.get("maker_id");
    return request({url:'/press/editPress.php',data:qs.stringify(data),method:'post'})
}

