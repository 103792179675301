<template>
    <div>
          <a-page-header
            class="header_title"
            :title="title"
            @back="() => $router.go(-1)"
        />
          <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" @submit="handleSubmit">
            <a-form-item :label="$t('newslist.add.item_1')">
            <a-input
                placeholder="title"
                v-decorator="['title', { rules: [{ required: true, message: 'Please input your note!' }] }]"
            />
            </a-form-item>
          
             <a-form-item :label="$t('newslist.add.item_2')">
            <a-input
                placeholder="author"
                v-decorator="['author', { rules: [{ required: true, message: 'Please input your note!' }] }]"
            />
            </a-form-item>
             <a-form-item :label="$t('newslist.add.item_3')">
            <a-input
                  placeholder="keywords"
                v-decorator="['keywords', { rules: [{ required: true, message: 'Please input your note!' }] }]"
            />
            </a-form-item>
             <a-form-item :label="$t('newslist.add.item_4')">
               <a-date-picker   v-decorator="['post_date', {
                 rules: [{ type: 'object', required: true, message: 'Please select time!' }], }]" />
            </a-form-item>
            <a-form-item :label="$t('newslist.add.item_5')">
                 <Uploadimg :url="imageUrl"  @getImgUrl='getImgUrl($event)' />
            </a-form-item>
            <a-form-item :label="$t('newslist.add.item_6')">
                 <Editor id="tinymce" v-model="tinymceHtml" :init="editorInit"></Editor>
            </a-form-item>
             <a-form-item :wrapper-col="{ span: 6, offset: 9 }">
                <a-button type="primary" html-type="submit">
                    {{$t('newslist.add.btn_submit')}}
                </a-button>
            </a-form-item>
        </a-form>

       
    </div>
</template>

<script>

import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.inline.min.css'
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import Uploadimg from '@/components/content/UploadImg'
import moment from 'moment'
import {getPressDetail,addPress,editPress} from '@/network/pressrequest'
export default {
    name: 'NodeprojectEditnews',
    components: { Editor,Uploadimg },
    directives: {  },
    data() {
        return {
            title:'add',
             press_id:'',
             loading: false,
             imageUrl: '',
             form: this.$form.createForm(this, { name: 'coordinated' }),
             tinymceHtml: '',
             editorInit: {
                        skin_url: 'public/skins/ui/oxide',
                        height: 300, 
                        plugins:"link lists image code table colorpicker textcolor wordcount contextmenu",
                        content_css:'public/skins/content/default/content.css',
                        
                  }
        };
    },
    created(){
      if(this.$route.query.press_id){
        this.title = 'eidt';
        getPressDetail(this.$route.query.press_id)
        .then(res=>{
          console.log(res)
            if(res.header.code==200){
              let press=res.body.press
                this.form.setFieldsValue({
                  title:press.title,
                  keywords:press.keywords,
                  author:press.author,
                  post_date:press.post_date?moment(press.post_date):''
                })
                this.press_id=press.press_id
                this.imageUrl=press.img
                this.tinymceHtml=press.content
            }
        
        })
      }
   
    },
    mounted() {
        tinymce.init({})
    },
    methods: {
        getImgUrl(e){
          this.imageUrl=e
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                console.log(values);
                // console.log(values['date'].format('YYYY-MM-DD'))
                let newobj={
                  title:values.title,
                  author:values.author,
                  content:this.tinymceHtml,
                  img:this.imageUrl,
                  keywords:values.keywords,
                  post_date:values['post_date'].format('YYYY-MM-DD')
                }

                if(this.press_id){
                newobj.press_id=this.press_id
                editPress(newobj)
                  .then(res=>{
                    if(res.header.code==200){
                      this.$message.success('success')
                      this.$router.back(-1)
                      return ;
                    }
                    this.$message.success(res.header.msg)
                    })
                    return ;
                }
                addPress(newobj)
                .then(res=>{
                  console.log(res)
                  if(res.header.code==200){
                    this.$message.success('success')
                    this.$router.back(-1)
                    return ;
                  }
                   this.$message.success(res.header.msg)
                })


                }
            });
        },

    },
};
</script>

<style  scoped>

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

</style>