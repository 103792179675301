<template>
    <div>
           <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :data="{maker_id:'register'}"
                action="https://shady.ecomgear.cn/kenneth_testing/maker/img/uploadPic.php"
                :before-upload="beforeUpload"
                @change="handleChange"
            >
                <img class="uploadimg" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                    Upload
                </div>
                </div>
            </a-upload>  
    </div>
</template>

<script>

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
    props:{
      url:{type:String,default:''},
    },
   data() {
    return {
      loading: false,
      imageUrl:this.$props.url,
    };
  },
  watch:{
    url(){
      this.imageUrl=this.$props.url
    }
  },
  methods: {
   
    handleChange(info) {
      // console.log(info)
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'error') {
        // Get this url from response in real world.
          this.imageUrl = "error";
          this.loading = false;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
        });
        if(info.file.response.header.code==200){
           this.imageUrl = info.file.response.body.url;
           this.$emit('getImgUrl',info.file.response.body.url);
        }
        this.loading = false;

      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
     if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style  scoped>
  /* .uploadimg{max-height: 100%;max-width: 100%;} */
  .uploadimg{max-height: 86px;max-width: 100%;}
</style>